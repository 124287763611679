@import "styles/core";

.shopsButton {
  font-size: 15px !important;
  line-height: 1;

  @media screen and (min-width: $sm) {
    font-size: 20px !important;
  }
}
