@import "variables";

.headerIcon {
  height: 15px;

  @media screen and (min-width: $sm) {
    height: 20px;
  }
}

.headerTextIcon {
  font-size: 15px !important;
  line-height: 1;

  @media screen and (min-width: $sm) {
    font-size: 20px;
  }

  @media screen and (min-width: $md) {
    display: none !important;
  }
}