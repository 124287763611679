@import "styles/variables";

.product {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  margin-bottom: 15px;

  img {
    max-width: 80px;
    margin-right: 10px;
  }

  .right {
    display: flex;
    flex-direction: column;

    .name {
      font-size: 14px;
      color: $primary-color;
      font-weight: bold;
      display: block;
      text-align: left;
    }

    .price {
      font-size: 15px;
      font-weight: bold;
      color: $secondary-color;
    }
  }
}
