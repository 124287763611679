@import "styles/variables";
.modal {
  max-width: 780px;

  :global(.ant-modal-body) {
    padding: 0; //24px;
  }

  :global(.ant-modal-footer) {
    border-top: 0;
    padding: 24px;
    text-align: center;
  }

  .header {
    color: $primary-color;
    font-weight: 900;
    letter-spacing: 0;
    text-align: center;
    padding: 12px 12px 8px 12px;

    @media (min-width: $sm) {
      padding: 24px 24px 16px 24px;
    }

    h2 {
      font-size: 15px;
      line-height: 18px;
      color: $primary-color;
      padding: 16px 0;

      @media (min-width: $sm) {
        font-size: 25px;
        line-height: 28px;
      }

      @media (min-width: $md) {
        font-size: 32px;
      }
    }
    button{
      line-height: 20px;
    }
  }

  section {
    padding: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    @media (min-width: $sm) {
      flex-direction: row;
    }
    .left {
      width: 100%;
      background: $primary-color;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 12px 0;
      
      @media (min-width: $sm) {
        width: 40%;
        max-height: 380px;
        overflow-y: scroll;
        ul.stores{
          display: none;
        }
      }
      @media (min-width: $md) {
        width: 34%;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          color: rgba(255, 255, 255, 0.7);
          font-size: 14px;
          letter-spacing: 0;
          padding: 10px 30px;
          cursor: pointer;
          font-weight: 600;
          position: relative;

          &::after {
            content: "";
            display: inline-block;
            border: solid rgba(255, 255, 255, 0.2);
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 2px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            position: absolute;
            right: 20px;
            top: 50%;
            margin-top: -4px;
          }

          &.active,
          &:hover {
            color: $primary-color;
            background: $secondary-color;
          }

          &:first-child {
            padding-top: 10px;
          }
        }
      }
    }
    .right {
      width: 100%;
      background: #f1f1f7;
      position: relative;
      display: none;

      @media (min-width: $sm) {
        max-height: 380px;
        width: 60%;
        display: block;
      }

      @media (min-width: $md) {
        max-height: 380px;
        width: 66%;
      }

      .loading {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255,255,255,0.7);
        text-align: center;
        flex-direction: column;
        font-size: 15px; 
        z-index: 10;
        span {
          margin-top: 5px;
        }
      }
    }

    ul.stores {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: 15px;

      @media (min-width: $sm) {
        margin-top: 0;
      }

      li {
        color: $primary-color;
        font-size: 14px;
        font-weight: 900;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0  0 10px 0;

        &:not(:last-child) {
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.04);
        }

        @media (min-width: $xs) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 0;

          &:not(:last-child) {
            margin-bottom: 5px;
            border: none;
          }
        }

        .buttons {
          display: flex;
          flex-direction: row;
          margin-top: 5px;

          @media (min-width: $xs) {
            margin-top: 0;
          }

          a,
          button {
            font-weight: 300;
            padding: 5px 10px;
            height: 35px;
            cursor: pointer;
            text-align: center;
            margin-left: 10px;
          }

          a {
            color: $primary-color;
            border: 1px solid $primary-color;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            padding: 9.8px 15px;
            border-radius: 24px;
            background: white;
            line-height: 1em;
            white-space: nowrap;
            display: inline-block;
            margin-right: 5px;
          }

          button {
            font-weight: 300;
            padding: 5px 10px;
            color: $primary-color;
            span {
              line-height: 1.2em;
            }

            &:global(.ant-btn-primary) {
              color: white;
            }

            &:global(.ant-btn-dangerous) {
              color: $unavailable-color;
            }
          }
        }
      }
    }
  }

  .footer {
    padding: 24px;
    text-align: center;
  }

  .step1 {
    .content {

      .right {
        background: url("/img/map.png") no-repeat center center;
        background-size: cover;
        max-height: 380px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        p {
          max-width: 280px;
          color: #ffffff;
          font-size: 62px;
          line-height: 72px;
          margin-bottom: 0;
          z-index: 2;

          svg {
            fill: #ffffff;
            margin-bottom: 10px;
          }
        }

        &::before {
          content: "";
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  .step2 {
    .right {
      padding: 24px;
      overflow-y: auto;
    }
  }
}
