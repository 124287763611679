@import "variables";

.container {
  max-width: 1440px;
  margin: 0 auto;
  &.guttered {
      margin-top: $general-gutter;
      margin-bottom: $general-gutter;
      @media (max-width: $xl) {
        padding-left: $general-gutter;
        padding-right: $general-gutter;
      }      
  }
}

.row {
  position: relative;
  clear: both;
  width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
  @media (min-width: $sm) {
    flex-direction: row;
  }
}

.col {
  display: block;
  width: 100%;
  padding: 0 $general-gutter;

  @media (min-width: $sm) {
    &.col-6 {
      width: 50%;
      flex-basis: 50%;
    }

    &.col-3 {
      width: 25%;
      flex-basis: 25%;
    }

    &.col-9 {
      width: 75%;
      flex-basis: 75%;
    }

    &.col-4 {
      width: 33.33%;
      flex-basis: 33.33%;
    }

    &.col-8 {
      width: 66.66%;
      flex-basis: 66.66%;
    }
  }
}

.image {
  &, img {
    max-width: 100%;
    height: auto;
  }
}
