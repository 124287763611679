@import "styles/variables";

.container {
  max-width: 1400px;
  margin: 0 auto;
}

.row {
  position: relative;
  clear: both;
  width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
  @media (min-width: $sm) {
    flex-direction: row;
  }
}

.col {
  display: block;
  width: 100%;
  padding: 0 $general-gutter;
  &.no-padding {
    padding: 0;
  }
  @media (min-width: $sm) {
    &.col-6 {
      width: 50%;
      flex-basis: 50%;
    }

    &.col-3 {
      width: 25%;
      flex-basis: 25%;
    }

    &.col-2 {
      width: 16.66%;
      flex-basis: 16.66%;
    }

    &.col-9 {
      width: 75%;
      flex-basis: 75%;
    }

    &.col-4 {
      width: 33.33%;
      flex-basis: 33.33%;
    }

    &.col-8 {
      width: 66.66%;
      flex-basis: 66.66%;
    }
  }
}

#footer {
  background-color: $footer-color;
  color: white;
  padding-bottom: 30px;
  .container {
    max-width: 1200px;
  }
  a {
    color: white;
    &.map-anchor {
      color: $secondary-color;
    }
    &:hover {
      color: $secondary-color;
    }
  }
  .socials {
    text-align: center;
    padding: 30px;
    font-size: 1.1em;
  }
  h3 {
    color: $secondary-color;
    margin-bottom: 20px;
    font-size: 1em;
    font-weight: bold;
    padding-left: 15px;
  }
  address {
    font-style: normal;
    font-size: 0.9em;
    text-align: center;
    margin-bottom: 30px;
    img {
      display: block;
      margin: 0 auto 20px;
    }
  }
  ul {
    list-style: none;
    padding: 0 0 0 15px;
    margin: 0;
    li {
      margin-bottom: 15px;
      a {
        font-size: 0.9em;
      }
    }
  }
  .copyright {
    text-align: center;
    margin-top: 80px;
    font-size: 0.8em;
  }
  @media (min-width: $sm) {
    padding-top: 30px;
    address {
      text-align: left;
      img {
        margin: 0 0 20px 0;
      }
    }
    .copyright {
      font-size: 1em;
    }
    h3,
    ul {
      padding-left: 0;
    }
    .socials {
      margin-bottom: 80px;
    }
  }
}

.newsletter {
  background: rgb(80, 35, 122);
  padding: 50px 0;
  color: white;

  h5 {
    color: $secondary-color;
    box-sizing: border-box;
    line-height: 1.25;
    font-size: 1.25em;
    font-weight: bold;
    margin: 0px 0px 20px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 15px;
    }
  }

  a.subscribe {
    display: inline-block;
    background-color: rgb(175, 202, 5);
    padding: 15px 35px;
    color: $primary-color;
    margin-top: 40px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.groupLogo {
  height: 40px;
  width: auto;
}
