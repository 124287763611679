@import 'variables';

@font-face {
  font-family: 'Euronics';
  src: url('../fonts/Euronics-Black.eot');
  src: url('../fonts/Euronics-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Euronics-Black.woff2') format('woff2'),
  url('../fonts/Euronics-Black.woff') format('woff'),
  url('../fonts/Euronics-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Euronics';
  src: url('../fonts/EuronicsHeavy.eot');
  src: url('../fonts/EuronicsHeavy.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuronicsHeavy.woff2') format('woff2'),
  url('../fonts/EuronicsHeavy.woff') format('woff'),
  url('../fonts/EuronicsHeavy.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Euronics';
  src: url('../fonts/EuronicsRegular.eot');
  src: url('../fonts/EuronicsRegular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/EuronicsRegular.woff2') format('woff2'),
  url('../fonts/EuronicsRegular.woff') format('woff'),
  url('../fonts/EuronicsRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/*==================================DISPLAY SETTINGS======================================*/
@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}
@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}
@media (min-width: 576px) {
  .hidden-xs-up {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .hidden-sm-up {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .hidden-md-up {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@for $i from 0 through 10 {
  .pt#{$i} {
    padding-top: 20px * $i;
  }
  .pb#{$i} {
    padding-bottom: 20px * $i;
  }
}

@for $i from 0 through 10 {
  .mt#{$i} {
    margin-top: 20px * $i;
  }
  .mb#{$i} {
    margin-bottom: 20px * $i;
  }
}

* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}

body {
  font-size: $f_size;
  line-height: $f_lineheight;
  font-family: $f_body;
  background: #F1F1F7;
  color: $c_font;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1.2;
  font-weight: 900;
}

h1 {
  color: $c_background_alt;
  font-size: 32px;
}

h2 {
  color: $c_background_alt;
  font-size: 28px;
  line-height: 2;
}

h3 {
  color: $c_background_alt;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.5;
}

h4 {
  color: $c_background_alt;
  font-size: 18px;
  font-weight: normal;
}
h5 {
  color: $c_background_alt;
}
p {
  line-height: 1.72;
}

blockquote {
  color: $c_background_alt;
  padding: 7px;
  background: rgba($c_background_alt, 0.1);
  width: fit-content;
  font-style: italic;
  margin-left: 5px;
  & p {
    margin: 0;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.breadcrumbs {
  &.detail {
    padding: 24px;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    font-size: 11px;
    line-height: 13px;
    color: rgba($c_background_alt,.5);
    li {
      &.sep {
        margin: 0 8px;
      }
    }
    a {
      color: $c_background_alt;
      text-decoration: none;
      font-weight: 900;
    }
  }
}

.button {
  height: 42px;
  display: inline-flex;
  align-items: center;
  font-weight: 900;
  text-decoration: none;
  color: $c_background_alt;
  padding: 5px 24px 0;
  background: $c_alt;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: all .2s;
  font-size: 13px;
  line-height: 16px;
  border-radius: 24px;
  &:hover {
    background: $c_background_alt;
    color: $c_background;
  }
  &.flat {
    width: 100%;
  }
  svg, i {
    &:last-child {
      margin-left: 8px;
    }
    &:first-child {
      margin-right: 8px;
    }
  }
  &.inverted {
    background: $c_background_alt;
    color: $c_background;
    &:hover {
      background: $c_alt;
      color: $c_background_alt;
    }
  }
}

svg {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em;
  width: 1em;
}

section {
  padding: 40px 0;
}

.container-fluid {
  padding: 0 24px;
}

.custom-select {
  padding: 7px 0;
  position: relative;
  .icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: $c_alt;
    font-size: 16px;
    line-height: 1;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 42px;
    border: none;
    background-color: #E6E6F3;
    font-family: $f_body;
    color: #496483;
    font-size: 13px;
    padding: 0 16px;
    &::-ms-expand {
      display: none;
    }
  }
}

.custom-checkbox {
  padding: 7px 0;
  label {
    display: flex;
    align-items: flex-start;
  }
  input {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    visibility: hidden;
  }
  span.check {
    height: 18px;
    width: 18px;
    border-radius: 3px;
    background-color: #E6E6F3;
    display: block;
    margin-right: 10px;
    color: transparent;
    font-size: 12px;
    text-align: center;
    i {
      margin-top: 3px;
    }
  }

  input:checked + span.check {
    background: #558A1D;
    color: white;
  }

  span.label {
    font-size: 13px;
    color: #496483;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.dropdown-items {
  .title {
    position: relative;
    text-align: center;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #E6E6F3;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .text {
      background: $c_background;
      display: inline-block;
      padding: 0 13px;
      position: relative;
      z-index: 1;
      color: #496483;
      cursor: pointer;
      .icon {
        color: $c_alt;
      }
    }
  }
  .content {
    display: none;
  }
}

nav.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  .count {
    color: #5B7C9B;
    font-size: 13px;
    line-height: 21px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background: $c_background;
      position: relative;
      font-size: 13px;
      &.dots {
        font-weight: 900;
        padding-top: 3px;
      }
      &:first-child {
        width: 48px;
        border-radius: 21px 0 0 21px;
        padding-left: 10px;
      }
      &:last-child {
        width: 48px;
        border-radius: 0 21px 21px 0;
        padding-right: 10px;
      }
      &:not(:last-child) {
        &:before {
          content: '';
          display: block;
          width: 1px;
          height: 18px;
          background: #E6E6F3;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &.active {
        padding-top: 3px;
        background: #E6E6F3;
        font-weight: 900;
      }
    }
    a {
      text-decoration: none;
      display: block;
      color: $c_background_alt;
      line-height: 1;
    }
  }
}

nav.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    .count {
      color: #5B7C9B;
      font-size: 13px;
      line-height: 21px;
    }
    .layout {
      button {
        padding: 0;
        background: transparent;
        border: none;
        font-size: 18px;
        color: #496483;
        margin-left: 14px;
        cursor: pointer;
        &.active {
          color: $c_background_alt;
        }
      }
    }
  }
  .filter-items {
    display: flex;
    label {
      input {
        opacity: 0;
        position: absolute;
      }
      span {
        display: flex;
        align-items: center;
        height: 42px;
        padding: 0 24px;
        background: $c_background;
        color: $c_background_alt;
        font-size: 13px;
        line-height: 16px;
      }
      input:checked + span {
        background: #E6E6F3;
        font-weight: 900;
        padding-top: 3px;
      }
      &:first-child {
        span {
          border-radius: 21px 0 0 21px;
        }
      }
      &:last-child {
        span {
          border-radius: 0 21px 21px 0;
        }
      }
      &:not(:last-child) {
        span {
          border-right: 1px solid #E6E6F3;
        }
      }
    }
  }
}

.tabs-section {
  .tab-heading {
    padding: 10px 40px;
    background: $c_alt;
    color: $c_background;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .heading {
    .tab {
      height: 64px;
      display: inline-flex;
      align-items: center;
      padding: 0 30px;
      border-radius: 16px 16px 0 0;
      background-color: rgba(21,61,140,0.05);
      color: #496483;
      font-size: 16px;
      line-height: 1;
      font-weight: 700;
      cursor: pointer;
      text-decoration: none;
      span {
        font-weight: 400;
        margin-left: 4px;
      }
      i {
        margin-right: 8px;
      }
      &.active {
        background: rgba($c_background,0.6);
        color: $c_background_alt;
        span {
          color: #496483;
        }
        i {
          color: $c_alt;
        }
      }
    }
  }
  .content {
    .tab {
      display: none;
      padding: 40px;
      background: rgba($c_background,0.6);
      border-radius: 0 0 16px 16px;
      &.show {
        display: block;
      }
    }
  }
}

.row-reverse {
  flex-direction: row-reverse;
}

.default-form {
  input,textarea {
    width: 100%;
    height: 42px;
    border-radius: 4px;
    background-color: #F1F1F7;
    color: $c_font;
    font-family: $f_body;
    font-size: 14px;
    padding: 0 24px;
    border: none;
    background-color: #F1F1F7;
  }
  textarea {
    height: auto;
    padding: 24px;
  }
  label {
    color: $c_background_alt;
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 4px;
  }
  .input-wrap {
    padding: 12px 0;
    button {
      padding: 0 24px;
      min-width: 160px;
    }
  }
}

.mfp-bg {
  background: rgba(#1D428A,.8);
}

.page-header {
  z-index: 20;
}
