@import "style/css/variables";
@import "styles/core";

.compareButton {

  .icon {
    position: relative;

    .badge {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      font-size: 8px;
      color: $c_background;
      background: $c_alt;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      position: absolute;
      left: -10px;
      bottom: -5px;
    }
  }
}

.compareButtonImg {
  @extend .headerIcon
}