@media (max-width: 1199px) {
  header {
    .actions {
      .search {
        max-width: 400px;
      }
      .right {
        a.cart {
          margin-left: 10px;
        }
      }
    }
    nav.main {
      button.menu-responsive {
        display: flex;
      }
      ul.categories {
        position: absolute;
        display: none;
        top: 100%;
        z-index: 1;
        left: 0;
        box-shadow: 0 0 40px rgba(0,0,0,.15);
        padding: 20px 0;
        font-size: 20px;
        text-align: center;
        height: auto;
        background: $c_background;
        a {
          display: block;
          padding: 10px 0;
        }
      }
    }
  }
  section.product-detail {
    .stores-price {
      .price-wrap {
        .price {
          font-size: 30px;
        }
      }
    }
  }
  main.products {
    section.products {
      .product-listing-list {
        .item {
          .heading {
            display: block;
          }
          .price-wrap {
            justify-content: flex-start;
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }
  }
}