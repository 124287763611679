@import "styles/variables";

@mixin default-background {
  background: {
    size: cover;
    repeat: no-repeat;
    position: center;
  }
}

@mixin gradient {
  background: linear-gradient(135deg, #2f70c0 0%, #153d8c 100%);
}

@import "global";

header {
  position: relative;
  z-index: 3;
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $c_background;
    .left {
      font-size: 13px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button.stores {
        height: 40px;
        background: $c_background_alt;
        color: $c_background;
        font-weight: 900;
        // display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        span {
          padding-left: 5px;
          font-weight: 300;
        }
        i {
          margin-left: 8px;
        }
        display: block;
        padding: 0 10px;
        font-size: 11px;
        width: 100%;
        @media screen and (min-width: $md) {
          display: inline-block;
          padding: 0 32px;
          font-size: 13px;
          margin-right: 30px;
          width: auto;
        }
      }
      .links {
        padding-right: 20px;
        a {
          color: $c_background_alt;
          text-decoration: none;
          font-weight: 700;
          &:not(:first-child) {
            &:before {
              content: "•";
              display: inline-block;
              margin: 0 8px;
            }
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .right {
      font-size: 13px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 30px;
      a {
        color: $c_background_alt;
        text-decoration: none;
        font-weight: 700;
        &:not(:first-child) {
          &:before {
            content: "•";
            display: inline-block;
            margin: 0 8px;
          }
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .actions {
    @include gradient;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    height: 80px;
    .dropdown-icon {
      margin-left: 10px;
      cursor: pointer;
      position: relative;
      .icon {
        font-size: 26px;
        text-decoration: none;
        color: $c_background;
        display: inline-flex;
        align-items: center;
        padding: 0 8px;
        line-height: 1;
        position: relative;
        i {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          font-size: 10px;
        }
      }
      .user-menu {
        display: none;
        position: absolute;
        right: 0;
        top: 100%;
        background: $c_background;
        z-index: 2;
        padding: 10px 15px;
        min-width: 240px;
        margin-top: 10px;
        border-radius: 8px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
        }
        a {
          display: block;
          padding: 12px 15px;
          border-bottom: 1px solid #f1f1f7;
          color: $c_font;
          font-size: 14px;
          transition: all 0.2s;
          &:hover {
            color: $c_background_alt;
          }
        }
        li:last-child {
          a {
            border: none;
          }
        }
      }
    }
    .search {
      max-width: 580px;
      width: 100%;
      position: relative;
      input {
        height: 32px;
        width: 100%;
        border-radius: 24px;
        border: none;
        padding: 0 100px 0 20px;
        font-family: $f_body;
        color: $c_font;

        @media screen and (min-width: $md) {
          height: 48px;
        }
      }
      .button {
        font-size: 18px;
        color: $c_background;
        position: absolute;
        right: 0;
        top: 0;
        height: 32px;
        padding: 0 24px;

        @media screen and (min-width: $md) {
          height: 48px;
          font-size: 24px;
          padding: 5px 24px 0;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      a {
        font-size: 26px;
        text-decoration: none;
        color: $c_background;
        display: inline-flex;
        align-items: center;
        padding: 0 8px;
        line-height: 1;
        svg {
          display: block;
        }
        &:last-child {
          padding-right: 0;
        }
        &.cart {
          margin-left: 60px;
          .price {
            font-size: 14px;
            line-height: 17px;
            margin-right: 10px;
          }
          .icon {
            position: relative;
          }
          .badge {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            font-size: 8px;
            color: $c_background;
            background: $c_alt;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            position: absolute;
            right: -10px;
            top: 0;
          }
        }
      }
    }
  }
  nav.main {
    background: $c_background;
    height: 48px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button.submemu {
      background: transparent;
      border: none;
      padding: 0;
      font-size: 20px;
      color: $c_background_alt;
      margin-left: 30px;
      cursor: pointer;
    }
    button.menu-responsive {
      background: transparent;
      border: none;
      padding: 0;
      font-size: 20px;
      color: $c_background_alt;
      margin-right: 30px;
      display: none;
      align-items: center;
      cursor: pointer;
      span.text {
        font-size: 14px;
        margin-right: 10px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .megamenu {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      background: $c_background;
      padding: 80px;
      box-shadow: 0 12px 24px 0 rgba(21, 61, 140, 0.2);
      display: none;
      .column {
        min-width: calc(2 / 12 * 100%);
      }
      .box-item {
        padding: 15px;
      }
      h3 {
        color: $c_background_alt;
        font-size: 16px;
        position: relative;
        .arrow {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
      ul {
        margin: 18px 0;
        padding: 0;
        list-style: none;
        a {
          color: #4d5c6e;
          font-size: 14px;
          line-height: 2;
          white-space: normal;
        }
      }
    }
    ul.categories {
      margin: 0;
      padding: 0 0 0 30px;
      list-style: none;
      display: flex;
      height: 48px;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      width: 100%;
      li{
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
      }
      .highlight{
        background: $secondary-color;
        position: relative;
        padding: 0 25px;
        &::before{
          content: "";
          background: $secondary-color;
          height: calc(100% + 8px);
          top: -4px;
          left: 0;
          width: 100%;
          position: absolute;
          z-index: 1;
          @media (max-width: 1290px) {
            content: unset;
          }
        }
        a{
          color: $primary-color;
          position: relative;
          font-weight: bold;
          z-index: 2;
        }
      }
    }
    a {
      color: $c_background_alt;
      text-decoration: none;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.promo-index {
  @media (max-width: $lg) {
    padding: 0 !important;
  }
}

section.promo {
  margin: 24px 0;
  padding: 0;
  .promo-slider {
    .slick-arrow {
      position: absolute;
      top: calc(50% + 30px);
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($secondary-color, 0.8);
      font-size: 32px;
      color: $c_background;
      border: none;
      z-index: 1;
      cursor: pointer;
      transition: all 0.2s;
      border-radius: 50%;
      &:before{
        position: absolute;
        top: 53%;
      }
      &:hover {
        background-color: $secondary-color;
      }
      @media (min-width: $lg) {
        &.left {
          left: -23px !important;
        }
        &.right {
          right: -19px !important;
        }
      }
      &.left {
        left: -18px;
      }
      &.right {
        right: -18px;
      }
      @media (max-width: 575px) {
        display: none;
      }
    }
    .slick-dots {
      margin: 0;
      padding: 0;
      list-style: none;
      position: absolute;
      left: 50%;
      bottom: 15px;
      transform: translateX(-50%);
      @media (min-width: $xs) {
        display: none !important;
      }
      @media (max-width: $xs) {
        width: 100%;
        background: $main-bg-color;
        height: 40px;
        bottom: -34px;
        align-items: center;
        li{
          border: 2px solid $secondary-color;
          opacity: 1;
          &.slick-active {
            border-color: $primary-color;
            background: $primary-color;
          }
          button{
            height: 0;
          }
        }
      }
      li {
        cursor: pointer;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $c_background;
        opacity: 0.3;
        display: inline-block;
        margin: 3px;
        transition: all 0.2s;
        &.slick-active {
          opacity: 1;
        }
      }
    }
    .slick-track {
      display: flex;
    }
    .slick-list {
      //overflow: visible;
    }
    .slick-slide {
      height: auto;
    }
    .slide {
      border-radius: 16px;
      color: $c_background;
      @include gradient;
      padding: 80px 0;
      position: relative;
      height: 100%;
      &:before {
        content: "";
        display: block;
        position: absolute;
        right: -100px;
        top: 0;
        background: url(../img/ornament.svg) 100% 33% no-repeat;
        background-size: cover;
        width: 50%;
        height: 100%;
      }
      h2 {
        font-size: 48px;
        margin-bottom: 30px;
      }
      .image {
        position: absolute;
        left: 47%;
        bottom: 0;
        max-height: calc(100% - 40px);
      }
    }
  }
}

.promo-slider div picture img {
  @media (min-width: $lg) {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }
}

section.icons {
  .item {
    display: flex;
    align-items: center;
  }
  .icon {
    font-size: 40px;
    margin-right: 30px;
  }
  .text {
    font-size: 16px;
    line-height: 24px;
    color: $c_background_alt;
    max-width: 180px;
  }
}

section.product-slider-section {
  .sliders {
    position: relative;
  }
  .slider-tabs {
    color: $c_background_alt;
    font-size: 32px;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .item {
      opacity: 0.5;
      padding: 24px;
      &.active {
        opacity: 1;
      }
    }
  }
  &.alt {
    .wrapper {
      background: transparent;
    }
    .slick-list {
      margin: 0 -15px;
    }
    .slick-slide {
      margin: 0 15px;
    }
    .product-slider {
      padding: 0;
      .item {
        background: white;
        border-radius: 16px;
        padding: 30px;
        max-width: 260px;
      }
    }
  }
  .wrapper {
    background: $c_background;
    border-radius: 16px;
    padding: 40px 0;
    display: none;
    &.bannerWrap{
      padding: 10px 0;
      background: $alt-color;
      .slick-slider{
        padding: 0 10px;
      }
      .slick-list{
        padding: 7px 20px;
      }
    }
    &.visible {
      display: block;
    }
  }
  h2 {
    color: $c_background_alt;
    margin-bottom: 40px;
  }
  .product-slider {
    padding: 0 90px;
    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 64px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f1f1f7;
      font-size: 32px;
      color: $c_alt;
      border: none;
      z-index: 1;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background-color: $c_background_alt;
      }
      &.left {
        left: 0;
        border-radius: 0 8px 8px 0;
        box-shadow: 24px 12px 24px 0 rgba(21, 61, 140, 0.2);
      }
      &.right {
        right: 0;
        border-radius: 8px 0 0 8px;
        box-shadow: -24px 12px 24px 0 rgba(21, 61, 140, 0.2);
      }
    }
    .slick-dots {
      margin: 40px 0 0;
      padding: 0;
      list-style: none;
      text-align: center;
      li {
        cursor: pointer;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $main-bg-color;
        display: inline-block;
        margin: 3px;
        transition: all 0.2s;
        border: 2px solid $secondary-color;
        &.slick-active {
          opacity: 1;
          background: $primary-color;
          border-color: $primary-color;
        }
        button{
          height: 0;
        }
      }
    }
    .item {
      text-align: center;
      display: block;
      max-width: 210px;
      padding: 15px 15px 0;
      margin: 0 auto;
      color: $c_font;
      text-decoration: none;
      &.simpleItem{
        max-width: 280px;
      }
      &:hover {
        .image {
          transform: scale(1.05);
        }
        h3 {
          text-decoration: underline;
        }
      }
      .image {
        height: 180px;
        transition: all 0.2s;
        img {
          max-height: 100%;
        }
      }
      h3 {
        font-weight: 400;
        padding: 15px 0;
        min-height: 90px;
      }
      img {
        margin: 0 auto;
      }
      .price {
        color: $c_background_alt;
        font-size: 21px;
        font-weight: 900;
      }
      .old-price {
        position: relative;
        color: #b1b1b0;
        &:before {
          content: "";
          display: block;
          position: absolute;
          height: 2px;
          width: 130%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-15deg);
          transform-origin: center;
          background: #e8461a;
        }
      }
    }
  }
}

section.promo-space {
  position: relative;
  z-index: 1;
  h2 {
    margin-bottom: 16px;
  }
  img {
    position: absolute;
    right: 12%;
    top: -20px;
    max-height: 320px;
  }
  .wrapper {
    @include gradient;
    padding: 40px 0;
    border-radius: 16px;
    color: $c_background;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 50px;
      top: 50%;
      transform: translateY(-50%);
      background: url(../img/ornament.svg) 100% 33% no-repeat;
      background-size: cover;
      width: 50%;
      height: 100%;
    }
  }
}

section.cta {
  .container{
    padding: 0;
  }
  .wrapper {
    margin: 0 auto;
  }
  a,
  img {
    display: block;
    border-radius: 24px;
  }
  a {
    padding: 15px 0;
  }
}

section.partners {
  background: #e6e6f3;
  margin: 40px 0;
  position: relative;
  .button {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    box-shadow: 0 0 0 6px #f1f1f7;
    padding: 5px 36px 0;
  }
  .partners-slider {
    .wrap {
      padding: 16px;
      max-width: 180px;
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

footer {
  .icons {
    text-align: center;
    margin-bottom: 2px;
    padding: 30px 0;
    background: $c_background;
    h3 {
      color: #9ca7b5;
      font-size: 11px;
      line-height: 28px;
    }
  }
  .copyright {
    background: $c_alt;
    color: $c_background;
    font-size: 12px;
    line-height: 14px;
    padding: 35px 15px 30px 15px;
    text-align: center;
    p {
      margin: 0;
    }
  }
  .menu {
    background: $c_background;
    padding: 50px 0;
    h3 {
      font-size: 16px;
      color: $c_background_alt;
      margin-bottom: 20px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      a {
        color: #4d5c6e;
        text-decoration: none;
        display: block;
        padding: 5px 0;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .logo {
      display: block;
      margin-bottom: 20px;
    }
    .contact-info {
      a {
        display: flex;
        align-items: center;
        color: $c_background_alt;
        font-size: 16px;
        line-height: 28px;
        text-decoration: none;
        padding: 8px 0;
        .icon {
          font-size: 18px;
          width: 44px;
          height: 44px;
          border: 2px solid #ecf1f4;
          border-radius: 50%;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #9ca7b5;
        }
      }
    }
  }
  .newsletter {
    padding: 35px 0;
    @include gradient;
    .social {
      white-space: nowrap;
      a {
        font-size: 26px;
        color: rgba($c_background, 0.6);
        margin-right: 10px;
      }
    }
    .newsletter-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .form-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      p {
        margin: 0;
        font-size: 18px;
        font-weight: 900;
        line-height: 24px;
        color: $c_background;
        margin-right: 10px;
      }
    }
    form {
      max-width: 400px;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      input {
        height: 42px;
        width: 100%;
        border-radius: 24px;
        border: none;
        padding: 0 100px 0 20px;
        font-family: $f_body;
        color: $c_font;
      }
      .button {
        color: $c_background;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

main.wrapper {
  padding: 24px 0;
}

main.products {
  .products-container {
    max-width: 1440px;
    width: 100%;
    padding: 0 24px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
  }
  section.products {
    width: 100%;
    padding: 0;
    .row {
      margin: 0 -8px;
      flex-direction: row;
    }
    .column {
      padding: 4px;
    }
    .headline-image {
      padding: 16px 0;
      img {
        display: block;
        border-radius: 16px;
      }
    }
    .product-listing-list {
      display: none;
      .item {
        background: $c_background;
        border-radius: 16px;
        padding: 24px;
        margin: 8px 0;
        .first-row {
          display: flex;
        }
        .heading {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
        h2 {
          font-size: 20px;
          line-height: 30px;
          font-weight: 400;
          a {
            text-decoration: none;
            color: $c_font;
          }
        }
        .main-desc {
          font-size: 12px;
          line-height: 16px;
          color: #496483;
          margin: 10px 0;
        }
        .product-image {
          height: 180px;
          width: 180px;
          margin-right: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          color: $c_font;
          text-decoration: none;
          flex-shrink: 0;
          img {
            display: block;
            max-height: 100%;
            margin: 0 auto;
          }
          .badges-left {
            position: absolute;
            left: 0;
            top: 0;
            .badge {
              border-radius: 2px;
              background-color: #e6e6f3;
              padding: 6px;
              color: #496483;
              font-size: 8px;
              font-weight: 900;
              line-height: 10px;
              text-transform: uppercase;
              margin-top: 8px;
              display: block;
              max-width: 60px;
              text-align: center;
            }
            .energy {
              display: flex;
              background: #558a1d;
              position: relative;
              color: $c_background;
              height: 28px;
              width: 54px;
              align-items: center;
              justify-content: center;
              font-weight: 900;
              border-radius: 8px 0 0 8px;
              font-size: 13px;
              padding-top: 3px;
              &:after {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 14px 0 14px 8px;
                border-color: transparent transparent transparent #558a1d;
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(100%);
              }
            }
          }
          .badges-right {
            position: absolute;
            right: 0;
            top: 0;
            width: 54px;
            img {
              display: block;
              margin-bottom: 4px;
            }
          }
        }
        .actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 16px 0;
          .button {
            margin-left: 8px;
          }
          .action {
            opacity: 0.5;
            color: #496483;
            font-size: 18px;
            text-decoration: none;
            padding: 0 8px;
          }
        }
        .store {
          display: flex;
          .store-item {
            display: flex;
            align-items: center;
            margin-right: 15px;
          }
          p {
            font-size: 12px;
            margin: 0;
          }
          .availibility {
            height: 7px;
            width: 7px;
            display: block;
            border-radius: 50%;
            margin-right: 8px;
            &.orange {
              background-color: #f1c400;
            }
            &.green {
              background-color: #558a1d;
            }
            &.red {
              background-color: #e8461a;
            }
          }
        }
        .price-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 10px;
          .price {
            font-size: 21px;
            font-weight: 900;
            color: $c_background_alt;
            white-space: nowrap;
          }
          .old-price {
            white-space: nowrap;
            position: relative;
            color: #b1b1b0;
            font-size: 14px;
            margin-left: 20px;
            &:before {
              content: "";
              display: block;
              position: absolute;
              height: 2px;
              width: 130%;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(-15deg);
              transform-origin: center;
              background: #e8461a;
            }
          }
        }
      }
    }
    .product-listing-grid {
      .item {
        background: $c_background;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px 24px 0;
        height: calc(100% - 16px);
        margin-bottom: 16px;
        transition: all 0.2s;
        &:hover {
          box-shadow: 0 0 36px 0 rgba(9, 24, 55, 0.2);
        }
        h2 {
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          a {
            text-decoration: none;
            color: $c_font;
          }
        }
        p.desc {
          font-size: 12px;
          line-height: 16px;
          color: #496483;
        }
        .manufacturer {
          font-size: 10px;
          font-weight: 900;
          line-height: 21px;
          text-transform: uppercase;
        }
        .store {
          display: flex;
          background-color: rgba(#e6e6f3, 0.2);
          margin: 0 -24px;
          border-radius: 0 0 16px 16px;
          padding: 18px 10px;
          .store-item {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          p {
            font-size: 12px;
            margin: 0;
          }
          .availibility {
            height: 7px;
            width: 7px;
            display: block;
            border-radius: 50%;
            margin-bottom: 8px;
            &.orange {
              background-color: #f1c400;
            }
            &.green {
              background-color: #558a1d;
            }
            &.red {
              background-color: #e8461a;
            }
          }
        }
        .actions {
          display: flex;
          align-items: center;
          padding: 16px 0;
          .button {
            border: 1px solid #e6e6f3;
            background: transparent;
            width: 100%;
            margin-right: 8px;
            &:hover {
              background: $c_background_alt;
              border-color: $c_background_alt;
            }
          }
          .action {
            opacity: 0.5;
            color: #496483;
            font-size: 18px;
            text-decoration: none;
            padding: 0 8px;
          }
        }
        .price-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .price {
            font-size: 21px;
            font-weight: 900;
            color: $c_background_alt;
          }
          .old-price {
            position: relative;
            color: #b1b1b0;
            font-size: 14px;
            &:before {
              content: "";
              display: block;
              position: absolute;
              height: 2px;
              width: 130%;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%) rotate(-15deg);
              transform-origin: center;
              background: #e8461a;
            }
          }
        }
        .product-image {
          height: 180px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          color: $c_font;
          text-decoration: none;
          img {
            display: block;
            max-height: 100%;
            margin: 0 auto;
          }
          .badges-left {
            position: absolute;
            left: 0;
            top: 0;
            .badge {
              border-radius: 2px;
              background-color: #e6e6f3;
              padding: 6px;
              color: #496483;
              font-size: 8px;
              font-weight: 900;
              line-height: 10px;
              text-transform: uppercase;
              margin-top: 8px;
              display: block;
              max-width: 60px;
              text-align: center;
            }
            .energy {
              display: flex;
              background: #558a1d;
              position: relative;
              color: $c_background;
              height: 28px;
              width: 54px;
              align-items: center;
              justify-content: center;
              font-weight: 900;
              border-radius: 8px 0 0 8px;
              font-size: 13px;
              padding-top: 3px;
              &:after {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 14px 0 14px 8px;
                border-color: transparent transparent transparent #558a1d;
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(100%);
              }
            }
          }
          .badges-right {
            position: absolute;
            right: 0;
            top: 0;
            width: 54px;
            img {
              display: block;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
    .favourite {
      padding: 32px 0;
      h2 {
        color: $c_background_alt;
        font-size: 20px;
        margin-bottom: 20px;
      }
      .item {
        text-decoration: none;
        background: $c_background;
        border-radius: 16px;
        color: $c_font;
        display: flex;
        align-items: center;
        padding: 15px 20px;
        &:hover {
          .content h3 {
            text-decoration: underline;
          }
        }
        .content {
          h3 {
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 3px;
          }
          p {
            margin: 0;
            opacity: 0.6;
            font-size: 12px;
          }
        }
        .image {
          width: 100px;
          height: 100px;
          position: relative;
          margin-right: 20px;
          display: flex;
          align-items: center;
          > img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: 0 auto;
          }
        }
        .badge {
          position: absolute;
          right: 0;
          top: -15px;
          img {
            display: block;
            height: 40px;
            margin: 5px 0;
          }
        }
      }
    }
    .sub-menu {
      padding: 16px 0;
      .item {
        text-decoration: none;
        color: $c_background_alt;
        background: $c_background;
        padding: 12px 20px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        &:hover {
          .title {
            text-decoration: underline;
          }
          .image {
            transform: scale(1.05);
          }
        }
        .title {
          font-weight: 900;
          font-size: 13px;
        }
        .image {
          width: 60px;
          height: 60px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          transition: all 0.2s;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
  aside.sidebar {
    background: $c_background;
    padding: 10px 24px;
    border-radius: 16px;
    width: 300px;
    flex-shrink: 0;
    margin-right: 24px;
    > .item {
      padding: 15px 0;
    }
    h3 {
      color: $c_background_alt;
      font-size: 12px;
      font-weight: 900;
      line-height: 21px;
      text-transform: uppercase;
    }
    .price-range-wrap {
      padding-top: 15px;
      h3 {
        margin-bottom: 30px;
      }
      .inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        span {
          margin: 0 8px;
          color: rgba($c_background_alt, 0.5);
        }
        .item {
          color: #747474;
          font-weight: 700;
          width: 100%;
        }
        input {
          height: 42px;
          width: 100%;
          opacity: 0.5;
          border-radius: 4px;
          background-color: rgba(#e6e6f3, 0.5);
          border: none;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #496483;
          font-family: $f_body;
        }
      }
      .ui-slider {
        border-radius: 3px;
        background: #e5e5e5;
        height: 6px;
        position: relative;
        width: calc(100% - 25px);
        margin: 0 auto;
        .ui-slider-range {
          background: $c_alt;
          position: absolute;
          height: 100%;
        }
        .ui-slider-handle {
          display: block;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          height: 22px;
          width: 22px;
          border-radius: 5px;
          background-color: $c_alt;
          box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.15);
          &.ui-state-active {
            background-color: $c_background_alt;
            border-color: white;
          }
        }
      }
    }
  }
}


.gallery-main-center{
  max-width: 590px;
  @media (max-width: $lg) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.gallery-relative{
  position: relative !important;
  max-width: 590px;
}

.product-image-antd {
  max-width: 590px !important;
  .ant-carousel .slick-slide img {
    display: block;
    height: 210px;
    object-fit: cover;
    @media (max-width: $lg) {
      width: 100%;
    }
  }
}



section.product-detail {
  padding-top: 0;
  h1 {
    margin: 8px 0 12px;
  }
  .products-container {
    max-width: 1440px;
    width: 100%;
    padding: 0 24px;
    margin: 0 auto;
  }
  .gallery {
    display: flex;
    align-items: flex-start;
  }
  section.promo {
    margin: 24px 0;
    padding: 0;
    .promo-slider {
      .slick-slide {
        margin: 0;
        padding: 0;
        border-radius: 16px;
        overflow: hidden;
      }
      .slick-arrow {
        margin: 0;
        &.right {
          right: 0;
        }
        &.left {
          left: 0;
        }
      }
    }
  }
  .present {
    border-radius: 8px;
    background-color: rgba(21, 61, 140, 0.05);
    padding: 8px;
    display: flex;
    align-items: center;
    margin: 40px 0;
    &-text {
      padding: 10px 0;
    }
    .image {
      width: 116px;
      background: $c_background;
      border-radius: 4px;
      padding: 8px;
      flex-shrink: 0;
      margin-right: 20px;
      img {
        display: block;
        margin: 0 auto;
      }
    }
    h3 {
      color: #ed5019;
      font-size: 16px;
      font-weight: 900;
      line-height: 21px;
    }
    p {
      margin: 0;
      color: #52708f;
      strong {
        color: $c_background_alt;
      }
    }
  }
  .badges-left {
    position: absolute;
    left: 24px;
    top: 30px;
    z-index: 1;
    .badge {
      border-radius: 2px;
      background-color: #e6e6f3;
      padding: 6px;
      color: #496483;
      font-size: 8px;
      font-weight: 900;
      line-height: 10px;
      text-transform: uppercase;
      margin-top: 8px;
      display: block;
      max-width: 60px;
      text-align: center;
    }
    .energy {
      display: flex;
      background: #558a1d;
      position: relative;
      color: $c_background;
      height: 28px;
      width: 54px;
      align-items: center;
      justify-content: center;
      font-weight: 900;
      border-radius: 8px 0 0 8px;
      font-size: 13px;
      padding-top: 3px;
      &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 0 14px 8px;
        border-color: transparent transparent transparent #558a1d;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(100%);
      }
    }
  }
  .badges-right {
    position: absolute;
    right: 28px;
    top: 14px;
    z-index: 1;
    width: 54px;
    img {
      display: block;
      margin-bottom: 4px;
    }
  }
  .gallery-wrap {
    width: calc(100% - 120px);
    position: absolute;
    min-height: 100%;
    display: flex;
  }
  .gallery-nav {
    width: 110px;
    flex-shrink: 0;
    margin-right: 10px;

    &-custom {
      max-height: 456px;
      overflow: auto;
    }

    .slick-slide {
      margin: 4px 0;
    }
    .slick-list {
      margin: -4px 0;
    }
    .slick-current {
      .wrap {
        background: white;
      }
    }
    .wrap {
      width: 100%;
      height: 110px;
      position: relative;
      background: rgba($c_background, 1);
      border-radius: 16px;
      transition: all 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      opacity: 0.7;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }
  .gallery-main {
    width: 100%;
    display: flex;
    min-height: 100%;
    max-height: 456px;
    .slide {
      position: relative;
      width: 100%;

      // &:before {
      //   content: "";
      //   display: block;
      //   width: 100%;
      //   padding-bottom: 100%;
      // }
      a {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $c_background;
        border-radius: 16px;
        padding: 25px;
      }
    }
  }
  .main {
    .stores-detail {
      background: $c_background;
      padding: 20px 32px;
      border-radius: 4px;
      form {
        position: relative;
        input {
          border: none;
          height: 42px;
          width: 100%;
          border-radius: 4px;
          background-color: #f1f1f7;
          padding: 0 70px 0 24px;
          font-size: 14px;
          font-family: $f_body;
          color: $c_font;
        }
        button {
          background: transparent;
          padding: 0;
          border: none;
          position: absolute;
          right: 24px;
          top: 50%;
          transform: translateY(-50%);
          color: $c_alt;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .top {
        margin-top: 40px;
      }
      .table-wrap {
        overflow: auto;
      }
      table {
        color: $c_background_alt;
        margin-top: 30px;
        width: 100%;
        td {
          padding: 5px;
          white-space: nowrap;
          &:last-child {
            width: 90px;
            text-align: right;
          }
        }
        .button {
          background: transparent;
          border: 1px solid rgba($c_background_alt, 0.2);
          padding: 0 15px;
          height: 30px;
          font-size: 12px;
          line-height: 15px;
          font-weight: 400;
          &:hover {
            background: $c_background_alt;
            border-color: $c_background_alt;
          }
        }
      }
    }
    .discounts {
      border-radius: 8px;
      background-color: rgba(21, 61, 140, 0.05);
      padding: 0 32px;
      margin-bottom: 30px;
      .first-item {
        padding: 16px 0;
        position: relative;
        .show-more {
          background: transparent;
          position: absolute;
          bottom: 16px;
          right: 0;
          border: none;
          color: $c_background_alt;
          font-size: 14px;
          font-weight: 900;
          line-height: 21px;
          cursor: pointer;
          padding: 0;
          span {
            display: inline-block;
            border-radius: 9.5px;
            background-color: #e8461a;
            height: 20px;
            color: $c_background;
            padding: 0 10px;
            line-height: 24px;
          }
        }
      }
      .next-items {
        display: none;
      }
      .discount-item {
        padding: 16px 0;
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 120px;
        .image {
          margin-right: 20px;
          width: 60px;
          height: 60px;
          flex-shrink: 0;
          img {
            display: block;
            max-height: 100%;
            margin: 0 auto;
          }
        }
        .button {
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background: transparent;
          position: absolute;
          border: 1px solid rgba(#153d8c, 0.2);
          &:hover {
            background: $c_background_alt;
            border-color: $c_background_alt;
          }
        }
      }
      .label {
        color: #e8461a;
        font-size: 14px;
        font-weight: 900;
        line-height: 20px;
        margin-bottom: 6px;
        img {
          margin-right: 4px;
          vertical-align: middle;
        }
      }
      h3 {
        color: #153d8c;
        font-size: 16px;
        font-weight: 900;
        line-height: 21px;
      }
      p {
        color: #496483;
        font-size: 14px;
        line-height: 21px;
        margin: 0;
      }
    }
    .manufacturer {
      font-size: 10px;
      font-weight: 900;
      line-height: 21px;
      text-transform: uppercase;
    }
    .parameters {
      color: #496483;
      font-size: 10px;
      font-weight: 900;
      line-height: 21px;
      text-transform: uppercase;
      span {
        margin-right: 10px;
      }
    }
    .desc {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 26px;
      color: #496483;
      a {
        color: $c_background_alt;
        font-weight: 900;
      }
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0 70px;

      .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .compare {
          text-decoration: none;
          color: #496483;
          font-weight: 700;
          cursor: pointer;
          display: flex;
          align-items: center;
          &:not(:first-child) {
            margin-top: 15px;
          }
          i {
            font-size: 18px;
            opacity: 0.5;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .top {
    display: flex;
    align-items: flex-start;
  }
  .price-wrap {
    margin-right: 90px;
    position: relative;
    .price {
      color: $c_background_alt;
      font-size: 48px;
      line-height: 1;
      white-space: nowrap;
    }
    .old-price {
      position: relative;
      color: #b1b1b0;
      font-size: 18px;
      white-space: nowrap;
      display: inline-block;
      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        width: 130%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-15deg);
        transform-origin: center;
        background: #e8461a;
      }
    }
  }
  .availibility-info {
    margin-top: 30px;
    .item {
      padding: 15px 25px;
      color: #496483;
      background: $c_background;
      display: flex;
      align-items: center;
      margin: 8px 0;
      border-radius: 4px;
      .icon {
        margin-right: 10px;
        &.orange {
          color: #f1c400;
        }
        &.green {
          color: #558a1d;
        }
        &.red {
          color: #e8461a;
        }
      }
      p {
        margin: 0;
      }
    }
  }
  .text {
    p {
      max-width: 720px;
      margin: 10px auto;
      font-size: 14px;
      line-height: 1.8;
      color: #52708f;
    }
    h2 {
      margin: 24px 0;
      color: $c_background_alt;
      font-weight: 700;
    }
    img {
      border-radius: 16px;
      display: block;
      margin: 80px auto;
    }
  }
  .tables {
    h2 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 20px;
      color: $c_background_alt;
    }
    table {
      margin: 20px auto;
      width: 100%;
      background: $c_background;
      border-radius: 8px;
      th,
      td {
        height: 64px;
        padding: 0 25px;
      }
      th {
        text-align: left;
        font-weight: 400;
      }
      td {
        text-align: right;
        font-weight: 700;
      }
      tr {
        &:not(:last-child) {
          th,
          td {
            border-bottom: 1px solid #efebea;
          }
        }
      }
    }
  }
  .reviews {
    display: flex;
    align-items: flex-start;
    padding: 50px;
    .review {
      width: 380px;
      background-color: #f1f1f7;
      padding: 32px;
      border-radius: 16px;
      .top-section {
        text-align: center;
        p {
          color: #496483;
          font-size: 14px;
          line-height: 1.2;
          margin: 0;
        }
      }
      .number {
        color: $c_background_alt;
        font-size: 64px;
        font-weight: 900;
        line-height: 1;
      }
      .stars {
        font-size: 24px;
        color: $c_alt;
        margin: 10px 0;
      }
      .lines {
        padding: 30px 0;
        table {
          width: 100%;
          td {
            width: 100%;
            &:first-child {
              font-weight: 900;
              font-size: 14px;
              color: $c_background_alt;
              white-space: nowrap;
              width: auto;
              i {
                color: $c_alt;
                margin: 0 10px;
              }
            }
            &:last-child {
              text-align: right;
              padding-left: 15px;
              white-space: nowrap;
              color: $c_background_alt;
            }
          }
        }
        .line-wrap {
          width: 100%;
          height: 4px;
          background: #e6e6f3;
          border-radius: 2px;
          position: relative;
          overflow: hidden;
          .line {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background: $c_background_alt;
            border-radius: 2px;
          }
        }
      }
    }
    .text-review {
      margin-left: 60px;
      h2 {
        color: $c_background_alt;
        font-size: 32px;
        font-weight: 400;
      }
      &__item {
        margin: 16px 0;
        background: $c_background;
        border-radius: 16px;
        padding: 32px;
        .pros-cons {
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
              position: relative;
              padding-left: 25px;
              color: #6588a6;
              font-size: 14px;
              font-weight: 700;
              line-height: 2;
              i {
                position: absolute;
                left: 0;
                top: 6px;
              }
            }
            &.pros {
              i {
                color: #689c00;
              }
            }
            &.cons {
              i {
                color: #ed5019;
              }
            }
          }
        }
        &-heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .date {
            opacity: 0.5;
            color: #496483;
            font-size: 10px;
          }
        }
        .stars {
          color: $c_alt;
          font-size: 14px;
          line-height: 21px;
          white-space: nowrap;
          margin-bottom: 8px;
        }
        .name {
          color: $c_background_alt;
          font-size: 14px;
          font-weight: 900;
        }
        p {
          color: #496483;
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1360px) {
  main.products {
    section.products {
      .product-listing-grid {
        .column {
          flex: 0 0 33%;
          max-width: 33%;
        }
      }
    }
  }
}

/*==================================RESPONSIVE=====================================*/
@import "responsive-lg";
@import "responsive-md";
@import "responsive-sm";
@import "responsive-xs";
