@media (max-width: 767px) {
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
  section {
    padding: 20px 0;
  }
  section.partners {
    margin: 20px 0;
  }
  section.product-detail {
    .gallery {
      flex-direction: column-reverse;
      &-nav {
        width: 100%;
        &-custom {
          display: flex;
          flex-direction: row;

          .slick-slide {
            min-width: 110px;
          }
        }
        .slick-list {
          margin: 0 -4px;
        }
        .slick-slide {
          margin: 8px 4px;
        }
      }
      &-wrap {
        width: 100%;
        position: relative;
        left: 0;
      }
    }
    section.promo {
      .promo-slider {
        .slick-arrow {
          height: 80px;
          width: 54px;
        }
      }
    }
    .text {
      h2 {
        margin: 10px 0;
      }
    }
  }
  section.product-detail {
    .price-wrap {
      margin-right: 40px;
      .price {
        font-size: 40px;
      }
    }
    .products-container {
      padding: 0 15px;
    }
    .text {
      img {
        margin: 20px auto;
      }
    }
  }
  header {
    nav {
      &.main {
        .megamenu {
          ul {
            &:first-child {
              margin-bottom: 0 !important;
            }
            &.not-first {
              margin-top: 0 !important;
            }
          }
        }
      }
    }
    .actions {
      .dropdown-icon{
        margin-left: 0;
        > .icon > a {
          padding: 0;
        }
      }
    }
  }
  footer {
    .newsletter {
      .social {
        a {
          margin: 0 5px;
        }
      }
      &-wrap {
        flex-direction: column;
      }
      .form-wrapper {
        justify-content: center;
        text-align: center;
      }
    }
  }
  section.promo {
    .promo-slider {
      .slide {
        &:before {
          background-size: contain;
        }
        .image {
          display: none;
        }
      }
    }
  }
  section.product-slider-section {
    &.alt {
      .wrapper {
        padding: 0;
      }
    }
    .slider-tabs {
      font-size: 20px;
      .item {
        padding: 12px;
      }
    }

    .wrapper {
      padding: 20px 0;
    }
  }
  main.products {
    section.products {
      .product-listing-list {
        display: none !important;
      }
      .product-listing-grid {
        display: block !important;
      }
    }
  }
  nav.pagination {
    flex-direction: column;
    .count {
      margin-top: 10px;
    }
  }

  footer .menu {
    padding: 30px 0;
  }
  .bannerWrap-mobile{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
