@import "styles/variables";

.menuItem{
	&:hover{
		.megaMenu{
			display: block;
		}
	}
}
.megaMenu{
	background: $main-bg-color;
	position: absolute;
	top: 100%;
	left: 0;
	display: none;
	width: 100%;
	padding: 80px 0;
	box-shadow: $general-shadow;
	a{
		color: $primary-color;
		font-weight: bold;
		font-size: 13px;
		display: block;
		margin: $general-gutter-05 0;
		padding: $general-gutter-05;
	}
	.subCategories{
		padding-left: $general-gutter-05;
		.content{
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			max-width: 100%;
		}
		a{
			display: inline-block;
			font-weight: 400;
			margin: 0;
			padding: 0;
		}
		.more{
			text-decoration: underline;
		}
	}
}