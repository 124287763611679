$f_size: 14px;
$f_lineheight: 1.5;
$f_body: 'Euronics';
$c_background_alt: #1D428A;
$c_font: #2F363D;
$c_alt: #F1C400;
$c_background: #ffffff;

// breakpoints
$breakpoint_sm: 575px
