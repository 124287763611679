@import "styles/variables";

.sideMenuContainer {
  width: 260px;
  height: 100vh;
  z-index: 9999;

  position: fixed;
  top: 0;
  right: -260px;

  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.95);
  overflow: auto;

  .sideBarContent {
    padding: 25px 15px;
  }

  .sideMenuList {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .sideMenuListItem {
    padding: 8px 0;
  }

  .highlight{
    background: $secondary-color;
    margin-left: -8px;
    a{
      color: $main-bg-color;
      padding-left: 8px;
    }
  }

  .sideMenuSpacer {
    margin-top: 15px;
    margin-bottom: 20px;
    border-top: 2px solid $primary-color;
  }

  .sideMenuTitle {
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 10px;
  }

  .menuCloseWrapper {
    text-align: right;
  }

  .menuClose {
    color: $primary-color;
    font-size: 24px;
    display: inline-block;
    padding: 5px;
  }
}

.sideMenuContainerVisible {
  right: 0 !important;
}