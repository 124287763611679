@import "styles/variables";

@media (max-width: 991px) {
  header {
    .topbar {
      display: block;
      .right {
        padding: 0 20px;
        display: none;
      }
    }
    .actions {
      height: auto;
      padding: 10px;
      flex-wrap: wrap;

      .logo {
        width: 100px;
        order: 1;
      }
      .search {
        order: 3;
        max-width: 100%;
        margin-top: 10px;
      }
      .right {
        order: 2;
      }
    }
    nav.main {
      .megamenu {
        padding: 40px 15px;
        ul {
          display: none;
        }
      }

      display: none;
    }
  }
  main.products {
    .products-container {
      display: block;
    }
    .filter-button {
      position: fixed;
      left: 0;
      width: 100%;
      bottom: 20px;
      z-index: 5;
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 0;

      div {
        padding: 10px 30px;
        box-shadow: 0 15px 30px 0 rgb(0 0 0 / 25%);
        background: #ffffff;
        border: 2px solid $primary-color;
        color: $primary-color;
        border-radius: 30px;

        &.open {
          background: $primary-color;
          color: #ffffff;
        }
      }
    }
    aside.sidebar {
      width: 100%;
      display: none;
      border-radius: 0 !important;

      &.open {
        display: block;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        width: 100%;
        background-color: white;
        z-index: 4;
        padding: 10px 20px 60px 10px;
        overflow: auto;
        padding: 10px 20px 100px 20px;
      }
    }
  }
  footer {
    .menu {
      text-align: center;
      h3 {
        margin: 20px 0 10px;
      }
      .contact-info a {
        justify-content: center;
      }
    }
    .newsletter {
      .form-wrapper {
        flex-wrap: wrap;
        p {
          margin-bottom: 10px;
        }
      }
    }
  }
  section.product-detail {
    .reviews {
      padding: 0;
      flex-direction: column;
      align-items: stretch;
      .review {
        width: 100%;
      }
      .text-review {
        margin-left: 0;
        margin-top: 40px;
      }
    }
  }
}
