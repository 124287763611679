@import "styles/variables";

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 50px);
  width: 100%;

  img {
    max-width: 100%;
  }

  .name {
    font-size: 17px;
    color: $primary-color;
    font-weight: bold;
    display: block;
    margin: 10px 0;
    text-align: center;
  }

  .price {
      font-size: 19px;
      font-weight: bold;
      color: $secondary-color;
  }
}
