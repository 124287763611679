@media (max-width: 575px) {
  .tabs-section {
    .tab-heading {
      padding: 10px 15px;
    }
    .content {
      .tab {
        padding: 25px 15px;
      }
    }
  }
  .container-fluid {
    padding: 0 15px;
  }
  nav.filter {
    display: block;
    .right {
      margin-top: 10px;
    }
  }
  main.products {
    .products-container {
      padding: 0 5px;
    }
    section.products {
      .favourite {
        padding: 0;
        .item {
          .content {
            h3 {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  header {
    .topbar {
      .left {
        button.stores {
          width: 100%;
          margin: 0;
        }
      }
    }
    .actions {
      .logo {
        width: 100px;
      }
      .right {
        a {
          &.cart {
            margin-left: 0;
            .price {
              display: none;
            }
          }
        }
      }
    }
  }
  section.product-detail {
    .top {
      align-items: stretch;
      flex-direction: column;
      text-align: center;
    }
    .price-wrap {
      margin-right: 0;
      margin-bottom: 20px;
    }
    .main {
      .actions {
        flex-direction: column !important;
        padding: 20px 0;

        .energy-label {
          width: 100%;
        }

        .buttons {
          margin-top: 30px;
          align-items: flex-start;
          padding: 0 10px;
          margin-right: auto;
        }
      }
      .discounts {
        .first-item {
          padding-right: 0;
          text-align: center;
          .show-more {
            position: static;
            margin-top: 8px;
          }
        }
        .discount-item {
          flex-direction: column;
          padding-right: 0;
          text-align: center;
          .image {
            margin: 0 0 10px;
          }
          .button {
            position: static;
            transform: none;
            margin-top: 6px;
          }
        }
      }
    }
  }
  section.product-slider-section {
    &.alt {
      .slick-slide {
        margin: 0 5px;
      }
      .slick-list {
        margin: 0 -5px;
      }
    }
    .product-slider {
      padding: 0;
      .item {
        .image {
          height: 130px;
        }
        h3 {
          font-size: 12px;
        }
      }
    }
  }
  section.product-detail {
    .present {
      flex-direction: column;
      margin: 20px 0;
      text-align: center;
      .image {
        margin: 0;
      }
      .text {
        padding: 10px;
      }
    }
    .tables {
      overflow: scroll;
      table {
        font-size: 12px;
        th,td {
          padding: 15px;
          height: auto;
        }
      }
    }
  }
  section.promo {
    .promo-slider {
      .slide {
        padding: 40px 0 60px;
      }
      @media (min-width: $lg) {
        .slick-slide {
          margin: 0 15px;
        }
      }

      .slick-arrow.right {
        right: 0;
      }
      .slick-arrow.left {
        left: 0;
      }
    }
  }
  section.cta a {
    padding: 5px 0;
  }
}
